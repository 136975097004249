<template>
  <v-row justify="center">
    <v-col md="10">
      <v-card :loading="loading">
        <v-card-text
          class="text-center title font-weight-bold"
          :class="{ 'subtitle-2 font-weight-bold': $vuetify.breakpoint.xs }"
          style="color: #FB8C00"
          ><h1 v-text="title"></h1
        ></v-card-text>
        <slot name="description" />
      </v-card>
    </v-col>
    <slot />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      loading: state => state.shared.loading
    })
  }
}
</script>

<style scoped></style>
